import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactForm from "components/ContactForm"

import { Content } from "page_components/office"

const Apartments = ({ location }) => {
  const title = "Biura i lokale"

  return (
    <Layout
      location={location}
      seo={{
        title: "Nowe biura na sprzedaż",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export default Apartments
